import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader.js';
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry.js';
import GUI from 'lil-gui';

/**
 * Base
 */
// Debug
const gui = new GUI();

// Canvas
const canvas = document.querySelector('canvas.webgl');

// Scene
const scene = new THREE.Scene();

/**
 * Textures
 */
const textureLoader = new THREE.TextureLoader();
const matcapTexture = textureLoader.load('textures/matcaps/8.png')
const youtubeTexture = textureLoader.load('images/youtube-logo-transparent.png');
const instagramTexture = textureLoader.load('images/instagram-logo-transparent.jpg');
const discordTexture = textureLoader.load('images/discord-logo-transparent.png');
const xTexture = textureLoader.load('images/x-logo-transparent.png');
matcapTexture.colorSpace = THREE.SRGBColorSpace;
youtubeTexture.colorSpace = THREE.SRGBColorSpace;
instagramTexture.colorSpace = THREE.SRGBColorSpace;
discordTexture.colorSpace = THREE.SRGBColorSpace;
xTexture.colorSpace = THREE.SRGBColorSpace;

/**
 * Fonts
 */
const fontLoader = new FontLoader()

fontLoader.load(
    '/fonts/helvetiker_regular.typeface.json',
    (font) =>
    {
        // Material
        const material = new THREE.MeshMatcapMaterial({ matcap: matcapTexture })

        // Text
        const headingGeometry = new TextGeometry(
            'BIRDDOGGAMING.COM',
            {
                font: font,
                size: 0.5,
                depth: 0.2,
                curveSegments: 12,
                bevelEnabled: true,
                bevelThickness: 0.03,
                bevelSize: 0.02,
                bevelOffset: 0,
                bevelSegments: 5
            }
        )
        headingGeometry.center();

        const subheadingGeometry = new TextGeometry(
            'COMING SOON',
            {
                font: font,
                size: 0.5,
                depth: 0.2,
                curveSegments: 12,
                bevelEnabled: true,
                bevelThickness: 0.03,
                bevelSize: 0.02,
                bevelOffset: 0,
                bevelSegments: 5
            }
        )
        subheadingGeometry.center();
        subheadingGeometry.translate(0, -0.75, 0);

        /**
         * Socials
         */
        const youtubeGeometry = new THREE.CircleGeometry(0.25);
        const instagramGeometry = new THREE.CircleGeometry(0.25);
        const discordGeometry = new THREE.CircleGeometry(0.25);
        const xGeometry = new THREE.CircleGeometry(0.25);

        youtubeGeometry.translate(-1.5, -1.5, 0);
        instagramGeometry.translate(-0.5, -1.5, 0);
        discordGeometry.translate(0.5, -1.5, 0);
        xGeometry.translate(1.5, -1.5, 0);

        // TODO: Get social photos to add to textures
        const youtubeMaterial = new THREE.MeshBasicMaterial({ color: 0xffffff, map: youtubeTexture });
        const instagramMaterial = new THREE.MeshBasicMaterial({ color: 0xffffff, map: instagramTexture });
        const discordMaterial = new THREE.MeshBasicMaterial({ color: 0xffffff, map: discordTexture });
        const xMaterial = new THREE.MeshBasicMaterial({ color: 0xffffff, map: xTexture });

        const headingMesh = new THREE.Mesh(headingGeometry, material);
        const subheadingMesh = new THREE.Mesh(subheadingGeometry, material);
        const youtubeMesh = new THREE.Mesh(youtubeGeometry, youtubeMaterial);
        const instagramMesh = new THREE.Mesh(instagramGeometry, instagramMaterial);
        const discordMesh = new THREE.Mesh(discordGeometry, discordMaterial);
        const xMesh = new THREE.Mesh(xGeometry, xMaterial);
        scene.add(headingMesh, subheadingMesh, youtubeMesh, instagramMesh, discordMesh, xMesh);

        // Donuts
        const donutGeometry = new THREE.TorusGeometry(0.3, 0.2, 32, 64)

        for(let i = 0; i < 100; i++)
        {
            const donut = new THREE.Mesh(donutGeometry, material)
            donut.position.x = (Math.random() - 0.5) * 10
            donut.position.y = (Math.random() - 0.5) * 10
            donut.position.z = (Math.random() - 0.5) * 10
            donut.rotation.x = Math.random() * Math.PI
            donut.rotation.y = Math.random() * Math.PI
            const scale = Math.random()
            donut.scale.set(scale, scale, scale)

            scene.add(donut)
        }
    }
)

/**
 * Sizes
 */
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}

window.addEventListener('resize', () =>
{
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight

    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
})

/**
 * Camera
 */
// Base camera
const camera = new THREE.PerspectiveCamera(75, sizes.width / sizes.height, 0.1, 100)
camera.position.x = 1
camera.position.y = 1
camera.position.z = 2
scene.add(camera)

// Controls
const controls = new OrbitControls(camera, canvas)
controls.enableDamping = true

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
    canvas: canvas
})
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

/**
 * Animate
 */
const clock = new THREE.Clock()

const tick = () =>
{
    const elapsedTime = clock.getElapsedTime()

    // Update controls
    controls.update()

    // Render
    renderer.render(scene, camera)

    // Call tick again on the next frame
    window.requestAnimationFrame(tick)
}

tick()